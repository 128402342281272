import React, { useEffect, useState } from "react";
import SEO from "../../components/seo";
import Layout from '../../components/winter-2022/Layout';
import Registered from '../../components/winter-2022/Registered';
import "../../styles/Global.css"
import "../../styles/layout.module.css"

const RegisteredPage = () => {

  return (
    <Layout type='secundary' QR >
      <SEO title="ISLA CORONA 2022" />
      <Registered />
    </Layout>
  );
}

export default RegisteredPage;
