import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import logo from "../../../images/corona-island/icon-scan.svg";
import Fb from "../../../images/social-media/share-facebook.svg";
import Ws from "../../../images/social-media/share-whatsapp.svg";
import Tw from "../../../images/social-media/share-twitter.svg";
import styles from "./registered.module.scss";
import "../../../styles/corona-island.scss";

const Registered = ({ QR }) => {

  return (
      <>
        <div className={styles.blur}></div>
        <section className={styles.container}>
          <div className={styles.content}>
            <h1>WOOPS</h1>
            <h3>PARECE QUE YA ESTÁS PARTICIPANDO</h3>
            <p>RECORDÁ QUE ANUNCIAMOS LOS GANADORES EL 03/08/22</p>
            <h4>COMPARTILO EN REDES SOCIALES</h4>

            <div className={styles.logosContainer}>
              <a href='http://www.facebook.com/sharer.php?u=https://www.cervezacorona.com.ar/' target="blank">
                <img src={Fb} className={styles.fb} />
              </a>
              <a href='https://api.whatsapp.com/send?text=%F0%9F%98%84Ceveza%20Corona%20te%20invita%20 a%20que%20participes%20por%20un%20viaje%20a%20CORONA%20ISLAND%F0%9F%8F%86%F0%9F%8F%86%20%0A%20https://www.cervezacorona.com.ar/' target="blank">
                <img src={Ws} className={styles.ws}/>
              </a>
              <a href='https://twitter.com/intent/tweet?text=Ceveza%20Corona%20te%20invita%20 a%20que%20participes%20por%20un%20viaje%20a%20CORONA%20ISLAND&url=https://www.cervezacorona.com.ar/&hashtags=#cervezacorona' target="blank">
                <img src={Tw} className={styles.tw}/>
              </a>
            </div>
            <AniLink
              to={`/${QR ? "islacoronaregistro" : "islacorona"}`}
              cover
              direction="left"
              duration={0.5}
              bg="#ffc311"
              className={styles.btn}
            >
              VOLVER
            </AniLink>
          </div>
        </section>
      </>
  );
};

export default Registered;
